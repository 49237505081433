import styled from 'styled-components';

export const ShopSubMenuTabContentStyled = styled.div`
    position: absolute;
    left: 0;
    display: none;
    width: 100%;
    padding: 20px 0;

    ${props => props.theme.devices.fullhdRealWidth} {
        padding-left: 5%;
        padding-right: 5%;
    }

    .column {
        padding-left: 5px;
        white-space: normal;
    }

    .sub-title-1 {
        font-family: ${props => props.theme.fonts.familySecondary};
    }

    ol > li > a {
        font-size: ${props => props.theme.globalSize.size7};
        font-weight: 400;
        line-height: 18px;

        justify-content: left;
        padding: 5px 0;
        border-bottom: none;

        &:hover {
            color: ${props => props.theme.colors.secondary};
        }
    }

    ol.two-columns {
        padding: 20px 0;
        column-count: 2;
        column-gap: 20px;
    }
`;
